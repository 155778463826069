.addButton {
    display: flex;
    align-items: flex-start;
    padding-left: 15px;
    padding-top: 10px;
  }
  
  .addButton button {
    width: 40px;
    height: 40px;
    background-color: #007fff;
    color: #fff;
    border-radius: 100%;
    border: none;
    outline: none;
    font-size: 22px;
    text-align: center;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.048),
      0 6.7px 5.3px rgba(0, 0, 0, 0.069), 0 12.5px 10px rgba(0, 0, 0, 0.085),
      0 22.3px 17.9px rgba(0, 0, 0, 0.101), 0 41.8px 33.4px rgba(0, 0, 0, 0.122),
      0 100px 80px rgba(0, 0, 0, 0.17);
    cursor: pointer;
  }
  